import React from 'react'
import axios from 'axios'
import { useState } from 'react'
import { motion } from 'framer-motion'
import ModalAction from '../../common/ModalAction'
import FloatingLabelTextInput from '../../common/FloatingLabelTextInput'
import Spinner from '../../common/Spinner'

const SportTypes = () => {

    const [modalMessage, setModalMessage] = useState('')
    const [isConfirmed, setIsConfirmed] = useState(false)
    const [isEmptyFields, setIsEmptyFields] = useState(false)
    const [isDisabled, setDisabled] = useState(false)

    const [onAddType, setOnAddType] = useState(false)

    const [sportType, setSportTypes] = useState('')

    const createSportType = () => {

        setShowModal(true)

        if (sportType === '') {
            setModalMessage('Empty field detected!')
            setIsEmptyFields(true)
            return
        }

        if (!isConfirmed) {
            setIsConfirmed(true)
            setModalMessage('Confirm your entry...')
            setIsEmptyFields(false)
            return
        }

        setIsConfirmed(false)
        setModalMessage('')
        setOnAddType(true)
        setDisabled(true)
        
        setTimeout(() => {
            axios
                .post('https://quizzasports.com/api/v1/create_sporttypes', { name: sportType })
                .then(data => {
                    console.log(data)
                    setOnAddType(false)
                    setModalMessage('Successful!')
                    setDisabled(false)
                    setSportTypes('')
                    setIsEmptyFields(true)
                })
                .catch(err => {
                    setIsEmptyFields(true)
                    setDisabled(false)

                    if (err.toString() === 'Error: Request failed with status code 400') {
                        console.log(err)
                        setModalMessage(`Error: ${sportType} is already added`)
                        setOnAddType(false)

                        return
                    }

                    console.log(err)
                    setOnAddType(false)
                    setModalMessage(err.toString())
                })
        }, 1500);
    }

    const AddSportTypeButton = () => {
        return (
            <div>
                {onAddType
                    ?
                    <motion.button
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: 1 } }}
                        exit={{ opacity: 0 }}
                        className="btn btn-info bg-transparent btn-block"
                    >
                        <Spinner text='Adding...' />
                    </motion.button>
                    :
                    <button className="btn btn_bg_color btn-block mt-4" onClick={createSportType}>
                        Add Sport Type
                    </button>
                }
            </div>
        );
    };

    const [showModal, setShowModal] = useState(false);

    const hideModal = () => {
        setShowModal(false)
        setIsConfirmed(false)
    }

    let modal;

    if (modalMessage !== '') {
        modal = (
            <ModalAction
                show={showModal}
                // title='Error'
                body={modalMessage}
                onHideHandler={hideModal}
                onClickHandler={isEmptyFields ? hideModal : createSportType}
                buttonText={isConfirmed ? 'Continue | Submit' : 'Close'}
                onCancelHandler={hideModal}
                cancelText={isEmptyFields ? null : 'Edit'}
            />
        )
    }

    return (
        <motion.div
            className='bg_logo bg-dark d-flex align-items-center justify-content-center'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}

        // initial={{ width: 0 }}
        // animate={{ width: '100%' }}
        // exit={{ x: window.innerWidth }}
        >
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center">

                        {/* Content */}
                        <div className="register">
                            <div className="container">
                                <div className="row">
                                    <div className='col-md-12 mb-5'>
                                        <h4 className="display-4" style={{ color: '#debf88' }}>Sport Types</h4>
                                    </div>
                                    <div className="col-md-8 m-auto">

                                        <FloatingLabelTextInput
                                            label='Sport Type'
                                            value={sportType}
                                            onChangeHandler={(e) => {
                                                setSportTypes(e.target.value)
                                                setIsConfirmed(false)
                                            }}
                                            disabled={isDisabled}
                                        />

                                        {modal}

                                        <AddSportTypeButton />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

export default SportTypes