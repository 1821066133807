import axios from 'axios'
import React from 'react'
import { useState } from 'react'
import { motion } from 'framer-motion'
import ModalAction from '../../common/ModalAction'
import FloatingLabelTextInput from '../../common/FloatingLabelTextInput'
import SelectListGroup from '../../common/SelectListGroup'
import Spinner from '../../common/Spinner'

const FootballQuestions = () => {

    const [modalMessage, setModalMessage] = useState('')
    const [isEmptyFields, setIsEmptyFields] = useState(false)
    const [isConfirmed, setIsConfirmed] = useState(false)
    const [isDisabled, setDisabled] = useState(false)

    const [onAddQuestion, setOnAddQuestion] = useState(false)

    const [qTitle, setQTitle] = useState('')
    const [optionA, setOptionA] = useState('')
    const [optionB, setOptionB] = useState('')
    const [optionC, setOptionC] = useState('')
    const [answer, setAnswer] = useState('')
    const [answerInfo, setAnswerInfo] = useState('')

    const createFootballQuestions = () => {

        setShowModal(true)

        if (qTitle === '' || optionA === '' || optionB === '' || optionB === '' || optionC === '' || answer === '') {
            setModalMessage('One or more fields are empty!')
            setIsEmptyFields(true)
            return
        }

        if (answer === 'Select The Correct Answer') {
            setModalMessage('Answer NOT selected')
            setIsEmptyFields(true)
            return
        }

        if (!isConfirmed) {
            setIsConfirmed(true)
            setModalMessage('Confirm your entry...')
            setIsEmptyFields(false)

            return
        }

        setIsConfirmed(false)
        setModalMessage('')
        setOnAddQuestion(true)
        setDisabled(true)

        const questionData = {
            questionTitle: qTitle,
            optionA: optionA,
            optionB: optionB,
            optionC: optionC,
            answer: answer
        }

        setTimeout(() => {
            axios
                .post('https://quizzasports.com/api/v1/questions/football', questionData)
                .then(data => {
                    console.log(data)
                    setOnAddQuestion(false)
                    setModalMessage('Successful!')
                    setDisabled(false)
                    setIsEmptyFields(true)
                    
                    
                    setQTitle('')
                    setOptionA('')
                    setOptionB('')
                    setOptionC('')
                    setAnswer('')
                })
                .catch(err => {
                    console.log(err)
                    setIsEmptyFields(true)
                    setOnAddQuestion(false)
                    setModalMessage(err.toString())
                    setDisabled(false)
                })
        }, 1500);
    }

    const options = [
        { label: 'Select The Correct Answer', value: 'null' },
        { label: 'Option "A"', value: 'optionA' },
        { label: 'Option "B"', value: 'optionB' },
        { label: 'Option "C"', value: 'optionC' }
    ]

    console.log(answer)

    const AddQuestionButton = () => {
        return (
            <div>
                {onAddQuestion
                    ?
                    <motion.button
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: 1 } }}
                        exit={{ opacity: 0 }}
                        className="btn btn-info bg-transparent btn-block"
                    >
                        <Spinner text='Adding...' />
                    </motion.button>
                    :
                    <button className="btn btn_bg_color btn-block mt-4" onClick={createFootballQuestions}>
                        Add Question
                    </button>
                }
            </div>
        );
    };

    const [showModal, setShowModal] = useState(false);

    const hideModal = () => {
        setShowModal(false)
        setIsConfirmed(false)
    }

    let modal;

    if (modalMessage !== '') {
        modal = (
            <ModalAction
                show={showModal}
                // title='Error'
                body={modalMessage}
                onHideHandler={hideModal}
                onClickHandler={isEmptyFields ? hideModal : createFootballQuestions}
                buttonText={isConfirmed ? 'Continue | Submit' : 'Close'}
                onCancelHandler={hideModal}
                cancelText={isEmptyFields ? null : 'Edit'}
            />
        )
    }

    return (
        <motion.div
            className='bg_logo bg-dark d-flex align-items-center justify-content-center'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}

        // initial={{ width: 0 }}
        // animate={{ width: '100%' }}
        // exit={{ x: window.innerWidth }}
        >
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center">

                        {/* Content */}
                        <div className="register">
                            <div className="container">
                                <div className="row">
                                    <div className='col-md-12 mb-5'>
                                        <h4 className="display-4" style={{ color: '#debf88' }}>Football Questions</h4>
                                    </div>
                                    <div className="col-md-8 m-auto">

                                        <FloatingLabelTextInput
                                            label='Question Title'
                                            value={qTitle}
                                            onChangeHandler={(e) => {
                                                setQTitle(e.target.value)
                                                setIsConfirmed(false)
                                            }}
                                            disabled={isDisabled}
                                        />
                                        <FloatingLabelTextInput
                                            label='Option "A"'
                                            value={optionA}
                                            onChangeHandler={(e) => {
                                                setOptionA(e.target.value)
                                                setIsConfirmed(false)
                                            }}
                                            disabled={isDisabled}
                                        />
                                        <FloatingLabelTextInput
                                            label='Option "B"'
                                            value={optionB}
                                            onChangeHandler={(e) => {
                                                setOptionB(e.target.value)
                                                setIsConfirmed(false)
                                            }}
                                            disabled={isDisabled}
                                        />
                                        <FloatingLabelTextInput
                                            label='Option "C"'
                                            value={optionC}
                                            onChangeHandler={(e) => {
                                                setOptionC(e.target.value)
                                                setIsConfirmed(false)
                                            }}
                                            disabled={isDisabled}
                                        />
                                        {/* <FloatingLabelTextInput
                                            label='Answer'
                                            value={answer}
                                            onChangeHandler={(e) => {
                                                setAnswer(e.target.value)
                                                setIsConfirmed(false)
                                            }}
                                        /> */}

                                        <SelectListGroup
                                            placeholder='Answer'
                                            name='status'
                                            value={answer}
                                            onChange={(e) => {
                                                // console.log(e.target.value)
                                                setAnswerInfo('Ensure the selected option is the current answer!')
                                                
                                                if (e.target.value === 'null') {
                                                    setShowModal(true)
                                                    setModalMessage('Answer is invalid: Make sure you select the correct option!')
                                                    setAnswer('Select The Correct Answer')
                                                    setAnswerInfo('')
                                                    setIsEmptyFields(true)
                                                    setIsConfirmed(false)
                                                    
                                                    return
                                                }

                                                if (e.target.value === 'optionA') setAnswer('optionA')
                                                if (e.target.value === 'optionB') setAnswer('optionB')
                                                if (e.target.value === 'optionC') setAnswer('optionC')
                                            }}
                                            options={options}
                                            info={answerInfo}
                                            disabled={isDisabled}
                                        />

                                        {modal}

                                        <AddQuestionButton />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

export default FootballQuestions