import React from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import Home from './pages/Home'
// import { AnimatePresence } from 'framer-motion/dist/framer-motion'
import { AnimatePresence } from 'framer-motion'
import FootballQuestions from './pages/FootballQuestions'
import SportTypes from './pages/SportTypes'

const AnimatedRoutes = () => {

    const location = useLocation()
    
    return (
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                <Route exact path='/' element={<Home />} />
                <Route exact path='/football-questions' element={<FootballQuestions />} />
                <Route exact path='/sport-types' element={<SportTypes />} />
            </Routes>
        </AnimatePresence>
    )
}

export default AnimatedRoutes