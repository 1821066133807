import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isCreateFootballQuestionsLinkClicked: false,
    isLogoClicked: false
}

export const navSlice = createSlice({
    name: 'nav',
    initialState: { value: initialState },
    reducers: {
        setCreateFootballQuestionsLinkClicked: (state, action) => {
            state.value.isCreateFootballQuestionsLinkClicked = action.payload
        },

        setLogoClickedState: (state, action) => {
            state.value.isLogoClicked = action.payload
        }
    }
})

export const { setCreateFootballQuestionsLinkClicked, setLogoClickedState } = navSlice.actions

export default navSlice.reducer