import React from 'react'
import { Button } from 'react-bootstrap'
import { Modal } from 'react-bootstrap'

const ModalAction = ({ show, title, body, buttonText, cancelText, onClickHandler, onCancelHandler, onHideHandler }) => {
    return (
        <div>
            <Modal show={show} onHide={onHideHandler} /** centered*/>
                {
                    title &&
                    <Modal.Header>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                }
                <Modal.Body>{body}</Modal.Body>
                <Modal.Footer>
                    {cancelText &&
                        <Button className='btn btn-outline-dark form-control' onClick={onCancelHandler}>
                            {cancelText}
                        </Button>
                    }
                    {buttonText &&
                        <Button className='btn btn-outline-dark form-control' onClick={onClickHandler}>
                            {buttonText}
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ModalAction