import React from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import logo from '../../assets/quizza_logo.png'
import { setLogoClickedState, setCreateFootballQuestionsLinkClicked } from '../../features/navSlice'

const Navbar = () => {

    const { isCreateFootballQuestionsLinkClicked, isLogoClicked } = useSelector((state) => state.nav.value)

    const dispatch = useDispatch()

    return (
        <nav className="sticky-top navbar navbar-expand-sm navbar-dark bg-dark">
            <div className="container">
                <Link
                    className="navbar-brand"
                    to={'/'}
                    onClick={() => dispatch(setLogoClickedState(true))}
                >
                    <img src={logo} alt='quizza' className='logo' />
                </Link>

                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#mobile-nav">
                    <span className="navbar-toggler-icon"></span>
                </button>
                
                {!isLogoClicked &&
                    <div className="collapse navbar-collapse" id="mobile-nav">

                        {/* <ul className="navbar-nav mr-auto">
                            <li className="nav-item">
                                <Link className="nav-link" to={'/profiles'}>

                                </Link>
                            </li>
                        </ul> */}
                        
                        <ul className="navbar-nav ml-auto">
                            {!isCreateFootballQuestionsLinkClicked
                                ? <li className="nav-item">
                                    <Link
                                        className="nav-link navbar_text_color"
                                        to={'/football-questions'}
                                        // style={{ color: '#FFFFFF' }}
                                        onClick={() => {
                                            dispatch(setCreateFootballQuestionsLinkClicked(true))
                                            dispatch(setLogoClickedState(false))
                                        }}
                                    >
                                        Create Football Question
                                    </Link>
                                </li>
                                : <li className="nav-item">
                                    <Link
                                        className="nav-link navbar_text_color"
                                        to={'/sport-types'}
                                        // style={{ color: '#FFFFFF' }}
                                        onClick={() => {
                                            dispatch(setCreateFootballQuestionsLinkClicked(false))
                                            dispatch(setLogoClickedState(false))
                                        }}
                                    >
                                        Create Sport Type
                                    </Link>
                                </li>
                            }
                        </ul>

                    </div>
                }

            </div>
        </nav>
    )
}

export default Navbar