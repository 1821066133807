import React from 'react'


const Spinner = ({ text }) => {
    return (

        <div className='landing'
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '40px',
                width: '100%'
            }}>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '40px',
                width: '100%'
            }}>
                <div className="loader">
                    <div className="spin" />
                </div>
                <p className="ml-3 text-white text-center h5">{text}</p>

            </div>
        </div>

    )
}

export default Spinner