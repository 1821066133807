import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { useDispatch } from 'react-redux'
import { setLogoClickedState, setCreateFootballQuestionsLinkClicked } from '../../features/navSlice'
import { useEffect } from 'react'


const Home = () => {
  
  const dispatch = useDispatch()
  
  useEffect(() => {
    dispatch(setLogoClickedState(true))
  }, [])

  return (
    <motion.div
      className='bg_logo bg-dark d-flex align-items-center justify-content-center'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}

    // initial={{ width: 0 }}
    // animate={{ width: '100%' }}
    // exit={{ x: window.innerWidth }}
    >
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">

              {/* Content */}
              <div className="register">
                <div className="container">
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      {/* <button className='btn btn_custom1' style={{ height: '4rem', width: '15rem' }}>
                        Create Football Questions
                      </button> */}
                      <Link
                        className="nav-link btn btn_bg_color text-dark"
                        style={{ width: '15rem' }}
                        to={'/football-questions'}
                        onClick={() => {
                          dispatch(setCreateFootballQuestionsLinkClicked(true))
                          dispatch(setLogoClickedState(false))
                        }}
                      >
                        Create Football Questions
                      </Link>
                    </div>
                    <div className="col-md-6 mb-3">
                      {/* <button className='btn btn_custom text-white' style={{ height: '4rem', width: '15rem' }}>
                        Create Sport Types
                      </button> */}
                      <Link
                        className="nav-link btn btn_bg_color1 text-white"
                        style={{ width: '15rem' }}
                        to={'/sport-types'}
                        onClick={() => {
                          dispatch(setCreateFootballQuestionsLinkClicked(false))
                          dispatch(setLogoClickedState(false))
                        }}
                      >
                        Create Sport Types
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </motion.div>
  )
}

export default Home